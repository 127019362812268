<template>
  <div id="main" class="bg-container-town-hall" >
    <div :path="path" style="position: relative;z-index: 8999;" v-if="town">
      <div
        class="modal fade "
        :id="'exampleModalCenter' + (index + 1)"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          role="document"
          style="max-width: 750px;"
        >
          <div class="modal-content mt-80" style="width:800px;">
            <div class="modal-header" style="padding:2px;">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                style="margin-left :710px;"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <img
                :src="path"
                style="width:750px;height:auto;"
                class="img-responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade bd-example-modal-lg"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      v-if="town"
    >
      <div class="modal-dialog modal-dialog modal-lg" role="document">
        <div class="modal-content modal-dialog modal-lg mt-80">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Nota béné</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center" v-html="note_well"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-for="townsPhoto in townsPhotos" :key="townsPhoto.id">
      <BreadCrumb
        v-if="parseInt(townsPhoto.on_top) === 1 && town.name"
        :bgUrl="'url(' + MAIRIE_URL + townsPhoto.path + ')'"
        :titre="town.name"
      ></BreadCrumb>
    </div>
    <section
    v-if="town"
      class="About-area pt-40 pb-60"
      id="about"
      style="margin-bottom: 0px;"
    >
      <div
        class="container-fluid"
        style="position: relative;z-index: 100; width: 95%;"
      >
        <div class="item" style="position: relative;z-index: 900;">
          <div class="col-md-12 mb-20">
            <h2 style="color: #b71c1c; text-align : center;">
              GÉNÉRALITÉS
            </h2>
            <img
              class="img-fluid mx-auto"
              src="/images/icon-flower.png"
              alt=""
              style="width: 100px; height: auto; display: block; margin-top: -30px;"
            />
            <div class="d-flex justify-content-center" v-if="town.generalities">
              <p style="text-align:center; font-size: 16px;">
                <!-- {{ town.generalities | truncate(600) }} -->
                {{ town.generalities }}
              </p>
            </div>
            <br />
            <div style="text-align: center;">
              <button class="default-button">
                <a
                  :href="town.wiki_link"
                  target="_blank"
                  style="font-weight: bold; color:#b71c1c;"
                  >LIRE PLUS</a
                >
              </button>
            </div>
          </div>
          <div class="col-md-12" style="width:110%; padding:0px;">
            <carousel :items-to-show="3" :autoplay="2000" :breakpoints="breakpoints" :wrap-around="true" v-if="townsPhotos != null">
              <slide v-for="(townsPhoto, index) in townsPhotos" :key="index">
                <town-hall-slide
                :image="MAIRIE_URL + townsPhoto.path"
                :index="index"
                :townsPhoto="townsPhoto"
                @path="modal(MAIRIE_URL + townsPhoto.path, index)"
              >
              </town-hall-slide>
              </slide>

              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
            <!-- <carousel
              v-if="townsPhotos != null"
              class="card-deck"
              :responsive="{
                0: { items: 1, nav: false },
                600: { items: 3, nav: false },
                1200: { items: 3, nav: false },
              }"
              :items="3"
              :autoplay="true"
              :nav="false"
            >
              <town-hall-slide
                v-for="(townsPhoto, index) in townsPhotos"
                :key="index"
                :image="MAIRIE_URL + townsPhoto.path"
                :index="index"
                :townsPhoto="townsPhoto"
                @path="modal(MAIRIE_URL + townsPhoto.path, index)"
              >
              </town-hall-slide>
            </carousel> -->
          </div>
        </div>
      </div>
    </section>
    <section
      class="gallery-area pt-100"
      id="gallery"
      style="margin-top: -60px;overflow-x: hidden;"
      v-if="town"
    >
      <!-- <div class="container-fluid" > -->
      <div class="row headz justify-content-center">
        <div class="col-lg-8">
          <h2 style="color: #b71c1c;">FORMALITÉS DE MARIAGE</h2>
          <img
            class="img-fluid mx-auto"
            src="/images/icon-flower.png"
            alt=""
            style="width: 100px; height: auto; display: block; margin-top: -30px;"
          />
        </div>
      </div>
      <!-- <div class="overlay overlay-bg"></div> -->
      <div style="background-image: url('/images/old_img/image.png');">
        <div style="background-color:#b71c1c; color: #ffffff; opacity: 0.8">
          <div
            class="d-flex align-items-center flex-column flex-md-row p-50"
            style="padding:15px;"
          >
            <!-- <div class="container"> -->
            <div class="d-flex justify-content-center flex-md-row flex-column">
              <div class="d-flex align-items-center flex-column col-md-6">
                <div class="mb-5 mt-4">
                  <div
                    class="h3 d-flex justify-content-center"
                    style="text-align:center; color:white; "
                    title="utilisateurInfo"
                  >
                    CONDITIONS GÉNÉRALES ET SUPPLEMENTAIRES
                  </div>
                  <div
                    class="d-flex justify-content-center pb-3"
                    title="daysCost"
                  >
                    <p
                      style="color: white; font-size: 16px; text-align:center;"
                    >
                      Pièces à fournir pour la constitution du dossier de
                      mariage
                    </p>
                    <br />
                  </div>
                  <div
                    v-if="town.supplementary_conditions"
                    class="d-flex justify-content-center"
                  >
                    <router-link
                      :to="
                        '/dossier-mairie-pour-organiser-son-mariage-cote-d-ivoire/' +
                          town.id
                      "
                      class="primary-button text-center"
                      style="color: white;border: 1px solid;border-radius:1px solid;width: auto;"
                    >
                      VOIR DOCUMENT
                    </router-link>
                  </div>
                </div>
                <div class="mt-0 p-0">
                  <div
                    class="h3 mb-0 p-2 bd-highligh"
                    style="padding:05px; text-align:center; color:white; "
                  >
                    JOURS ET COUTS DE CELEBRATIONS
                  </div>
                  <div
                    class="d-flex justify-content-center flex-column flex-md-row align-items-center row"
                    style="margin:auto;margin-top:20px"
                  >
                    <div v-for="(st, index) in str" :key="index">
                      <div class="col" v-if="st[1]">
                        <tr v-if="st[1] > 0">
                          <td style="width: 85px;">{{ st[0] }} :</td>
                          <td style="width: 105px;">
                            {{
                              st[1]
                                
                            }}
                          </td>
                        </tr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-items-center flex-column col-md-6 justify-content-center"
                style="border-left:2px solid; margin:auto"
              >
                <div
                  class="h3 bd-highligh d-flex mt-4"
                  style="text-align:center; color:white;"
                >
                  NOTA BÉNÉ
                </div>
                <div class="row p-5 d-flex justify-content-center">
                  <div v-html="town.note_well"></div>
                  <div v-if="town.note_well.length < note_well.length">
                    <button
                      class="default-button"
                      data-toggle="modal"
                      data-target=".bd-example-modal-lg"
                    >
                      voir plus
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>
    <section
      class="section-gap info-area"
      style="background-color: transparent"
      v-if="town"
    >
      <div class="container">
        <div class="item">
          <div class="row">
            <div class="col-md-6">
              <h3 style="color: #b71c1c;">TELEPHONE</h3>
              <p style="font-size: 16px;">
                {{ town.phone_numbers }}
              </p>
              <br />
              <h3 style="color: #b71c1c;">SITE WEB</h3>
              <p style="font-size: 16px;">
                <a :href="town.website_link" target="_blank">{{
                  town.website_link
                }}</a>
              </p>
              <br />
              <h3 style="color: #b71c1c;">ADRESSE</h3>
              <p style="font-size: 16px;">
                {{ town.adress }}
              </p>
              <br />
              <h3 style="color: #b71c1c;">EMAIL</h3>
              <p style="font-size: 16px;">
                {{ town.email }}
              </p>
              <br />
            </div>
            <div class="col-md-6">
              <iframe
                width="100%"
                height="450"
                frameborder="0"
                style="border:0"
                :src="
                  'https://www.google.com/maps/embed/v1/place?q=' +
                    town.latitude +
                    '%2C' +
                    town.longitude +
                    '&key=AIzaSyC4YnvQ9y2DwnKKX0U7YCDDSkLlz-aBhKg'
                "
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
          <div class="row pt-5" style="margin-bottom:-70px;">
            <p style="font-size:12px;color:red;font-style:italic;">
              NB: La complétude des informations pour la célébration d'un
              mariage est de la responsabilité de la mairie célébrante. Les
              informations sur joursdejoie sont fournies à titre indicatif. Nous
              vous conseillons de vous rendre dans la mairie de votre choix pour
              avoir l'exhaustivité des informations utiles à la célébration de
              votre mariage.
            </p>
          </div>
        </div>
      </div>
    </section>
    <town-hall-component></town-hall-component>
  </div>
</template>

<style>
.bg-container-town-hall {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-town-hall .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-town-hall::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/town-hall/town-halls.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import BreadCrumb from "@/components/Breadcrumb.vue";
import TownHallSlide from "@/components/common/ui/TownHallSlide";
import TownHallComponent from "@/components/town-hall/TownHallComponent.vue";
import { TOWN_HALL } from "@/config";
import axios from "axios";
// import truncate from "html-truncate";
import { useMeta } from 'vue-meta';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  setup () {
    useMeta({
      title:
        "Infos Mairie, Informations administratives pour la célébration de mariages civils",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "keywords",
          content:
            "Info mairies - procédure de mariage, trouver toutes les informations relatives aux procédures de mariage en Côte d’Ivoire - mariage civil en Côte d’Ivoire, loi du mariage",
        },
        {
          name: "description",
          content:
            "Toutes les informations relatives aux procédures de mariage en Côte d’Ivoire",
        },
      ],
    })
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    BreadCrumb,
    TownHallComponent,
    // carousel,
    TownHallSlide,
  },
  data: () => ({
    
      town: null,
      townsPhotos: {},
      selectPays: 0,
      pays: null,
      region: 0,
      index: null,
      mairies: 0,
      daysCosts: {},
      celebrations: {},
      onTop: null,
      note_well: null,
      str: [],
      path: null,
      MAIRIE_URL_API: TOWN_HALL.DEV_URL+API_PREFIX+API_VERSION,
      MAIRIE_URL: TOWN_HALL.DEV_URL,
      slide: {
        index: null,
        path: null,
      },
      breakpoints: {
      // 700px and up
      200: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    },
    
  }),
  created () {
    
    this.getTownDetails();
  },
  methods: {
    modal: function(path, index) {
      var app = this;
      app.path = path;
      app.index = index;
    },
    getTownDetails() {
      var self = this;
      // self.$Progress.start();
      var id = this.$route.params.id;
      axios
        .get(this.MAIRIE_URL_API + "town-halls/" + id)
        .then(function(res) {
          
          self.town = res.data;
          self.note_well = res.data.note_well;
          self.daysCosts = self.town.days_celebration_costs.split(";");
          self.daysCosts.pop();
          self.celebrations = self.daysCosts;
          // self.town.note_well = truncate(self.town.note_well, 510);
          var stro = [];
          self.celebrations.forEach((e) => {
            stro.push(e.split(":"));
          });
          stro.forEach((elts) => {
            if (elts[1] != 0) {
              self.str.push(elts);
            }
          });
          self.townsPhotos = self.town.photos;
          self.slide = self.townsPhotos.on_top;

          var vals = [];
          for (var i = 0; i < self.townsPhotos.length; i++) {
            if (self.townsPhotos[i].ontop == 1) {
              vals = self.townsPhotos[i].split(";");
            }
          }
        })
        .catch(function(error) {
          console.log("Error", error.response.data);
        });
    },
  },
};
</script>
