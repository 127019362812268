<template>
  <div id="main">
    <div v-if="town != null">
      <div v-for="(photo, key) in town.photos" :key="key">
        <Breadcrumb
          v-if="parseInt(photo.on_top) == 1 && town.name"
          :bgUrl="'url(' + MAIRIE_URL + photo.path + ')'"
          :titre="town.name"
        ></Breadcrumb>
      </div>
      <section
        class="About-area pt-40 pb-60"
        id="about"
        style="margin-bottom: 0px;"
      >
        <div
          class="container-fluid"
          style="position: relative;z-index: 100; width: 95%;"
        >
          <div class="item" style="position: relative;z-index: 900;">
            <div class="col-md-12 mb-20">
              <h2 style="color: #b71c1c; text-align : center;">
                DOSSIER DE MARIAGE
              </h2>
              <img
                class="img-fluid mx-auto"
                src="/images/icon-flower.png"
                alt=""
                style="width: 100px; height: auto; display: block; margin-top: -30px;"
              />
              <div class="d-flex justify-content-left">
                <p
                  style="font-size: 16px;"
                  v-html="town.supplementary_conditions"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="pt-150 pb-150" v-else>
      <spinner  />
    </div>
  </div>
</template>

<style scoped>
.bg-container-town-hall {
  width: 100%;
  position: relative;
}

.bg-container-town-hall .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-town-hall::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/town-hall/town-halls.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { TOWN_HALL } from "@/config";
import axios from "axios";
import { useMeta } from 'vue-meta';
export default {
  setup () {
    useMeta({
      title:
        "Dossier de Mariage d'une Mairie, Informations administratives pour la célébration de mariages civils",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "keywords",
          content:
            "Info mairies- procédure de mariage,organiser son mariage, organisation de mariage, trouver toutes les informations relatives aux procédures de mariage en Côte d’Ivoire- mariage civil en Côte d’Ivoire, loi du mariage",
        },
        {
          name: "description",
          content:
            "Toutes les informations relatives aux procédures de mariage en Côte d’Ivoire, Trouver des prestataires de mariage",
        },
      ],
    })
  },

  name: "weddingFolder",
  components: {
    Breadcrumb,
  },
  data: function() {
    return {
      town: null,
      MAIRIE_URL_API: TOWN_HALL.DEV_URL+API_PREFIX+API_VERSION,
      MAIRIE_URL: TOWN_HALL.DEV_URL,
    };
  },
  mounted: function() {
    this.getTownDetails();
  },
  methods: {
    getTownDetails: function() {
      var self = this;
      var id = this.$route.params.id;
      axios
        .get(this.MAIRIE_URL_API + "town-halls/" + id)
        .then(function(response) {
          self.town = response.data;
          // self.$Progress.finish();
        })
        .catch(function(error) {
          console.log("Error", error);
          // self.$Progress.finish();
        });
    },
  },
};
</script>
